body {
  font-family: "Public Sans", sans-serif;
  /* font-family: 'Poppins', sans-serif; */
  font-size: 0.9rem;
  margin-top: 0 !important;
  padding-top: 0 !important;
  color: #37352f;

}

#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
  text-decoration: none !important;
}

/* Disable text highlight globally */
.goog-text-highlight {
  background: none !important; /* Remove highlight background */
  background-color: transparent !important; /* Remove highlight background */
  border: none !important; /* Remove borders */
  box-shadow: none !important; /* Remove shadow */
  text-decoration: none !important; /* Prevent underline or other styles */
  color: inherit !important; /* Ensure text color remains unchanged */
  cursor: auto !important; /* Set to default cursor */
}

/* Remove hover effects */
.goog-text-highlight:hover {
  background: none !important;
  background-color: transparent !important; /* Remove hover highlight background */
  border: none !important; /* Remove hover border */
  box-shadow: none !important; /* Remove hover shadow */
  text-decoration: none !important; /* Prevent underline or other styles */
  cursor: auto !important; /* Set to default cursor */
}

/* Prevent any additional Google Translate tooltips or highlights */
.goog-tooltip {
  background: none !important;
  background-color: transparent !important; /* Remove hover highlight background */
  border: none !important; /* Remove hover border */
  box-shadow: none !important; /* Remove hover shadow */
  text-decoration: none !important; /* Prevent underline or other styles */
  cursor: auto !important; /* Set to default cursor */
}

.goog-tooltip:hover {
  background: none !important;
  background-color: transparent !important; /* Remove hover highlight background */
  border: none !important; /* Remove hover border */
  box-shadow: none !important; /* Remove hover shadow */
  text-decoration: none !important; /* Prevent underline or other styles */
  cursor: auto !important; /* Set to default cursor */
}

.goog-te-balloon-frame {
  display: none !important;
  visibility: hidden !important;
  pointer-events: none !important;
  text-decoration: none !important; /* Prevent underline or other styles */
}

/* Style the Google Translate widget */
.translate-widget {
  display: inline-block;
  position: relative;
  font-size: 0; /* Hide default Google branding text */
  margin-left: 15px; /* Add spacing between buttons */
}

/* Hide the Google branding completely (optional) */

.translate-widget .goog-logo-link,
.translate-widget .goog-te-gadget-icon,
.translate-widget .goog-te-gadget span {
  display: none !important;
}

/* Container styling */
#google_translate_element {
  font-family: "Poppins", sans-serif;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #5f49eb; /* Use primary brand color for border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

/* Dropdown styling */
.translate-widget {
  display: inline-block;
  position: relative;
  font-size: 0; /* Ensure branding text is hidden */
  margin-left: 15px;
}

.translate-widget .goog-te-gadget {
  font-size: 14px;
  line-height: normal;
}

.translate-widget .goog-te-combo {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #5f49eb; /* Brand border color */
  background-color: #ffffff; /* White background for clarity */
  font-family: "Poppins", sans-serif;
  color: #5f49eb; /* Brand text color */
}

/* Hide any Google branding banners if they appear */
.translate-widget .goog-te-banner-frame {
  display: none !important;
}

/* Hover effect for the dropdown */
.translate-widget .goog-te-combo:hover {
  background-color: #f7f7ff; /* Slightly off-white to highlight hover state */
  border-color: #5f49eb; /* Keep the brand border color */
  color: #5f49eb; /* Maintain brand text color */
}

/* Ensure the iframe is hidden and takes up no space */

iframe#\:2\.container {
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  visibility: hidden !important;
}

div.skiptranslate iframe {
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  visibility: hidden !important;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wand-color-2 {
  color: #ad309d;
}

.wand-bg-color-2 {
  background-color: #ad309d;
}

/* Smooth scroll effect for text areas */
.scroll-textarea {
  overflow-y: auto;
  max-height: 250px; /* You can adjust this based on your design */
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 transparent;
}

/* Custom scrollbar styling for WebKit browsers */
.scroll-textarea::-webkit-scrollbar {
  width: 8px;
}

.scroll-textarea::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-textarea::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 10px;
}

/* Button hover effects and transitions */
.soft-btn {
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.soft-btn:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.wand-color {
  color: #5f49eb;
}

.wand-border-color {
  border-color: #5f49eb;
}

.wand-bg-color {
  background-color: #5f49eb;
  transition: background-color 0.3s ease; /* Optional: adds a smooth transition */
}

.wand-bg-color:hover {
  background-color: #4a3bcf; /* Change this to the color you want on hover */
}

.wand-bg-color:active {
  background-color: #3a2cb5; /* Change this to the color you want on click */
}

.btn-wand {
  color: #fff; /* Text color */
  background-color: #5f49eb; /* Background color */
  border-color: #5f49eb; /* Border color */
}

.btn-wand:hover {
  background-color: #4a3bcf; /* Darker shade for hover */
  border-color: #4a3bcf;
  color: #fff; /* Text color */
}

.btn-wand:focus,
.btn-wand:active {
  background-color: #3a2cb5; /* Even darker shade for click/focus */
  border-color: #3a2cb5;
  color: #fff; /* Text color */
}

.btn-wand:disabled {
  background-color: #a9a9a9; /* Disabled state color */
  border-color: #a9a9a9;
  color: #fff; /* Text color */
}

.btn-wand-inverted {
  color: #5f49eb; /* Text color */
  background-color: #fff; /* Background color */
  border-color: #5f49eb; /* Border color */
}

.btn-wand-inverted:hover {
  color: #4a3bcf; /* Darker text color for hover */
  background-color: #f0f0f0; /* Light background for hover */
  border-color: #4a3bcf;
}

.btn-wand-inverted:focus,
.btn-wand-inverted:active {
  color: #3a2cb5; /* Even darker text color for click/focus */
  background-color: #e0e0e0; /* Slightly darker background for click/focus */
  border-color: #3a2cb5;
}

.btn-wand-inverted:disabled {
  color: #a9a9a9; /* Disabled text color */
  background-color: #fff; /* White background */
  border-color: #a9a9a9;
}

.btn-wand-inverted-bland {
  color: #333 !important; /* Muted black for text */
  background-color: #f9f9f9 !important; /* Light grey background */
  border-color: #ccc !important; /* Medium grey border */
  box-shadow: none !important; /* Prevent Bootstrap button shadow */
}

.btn-wand-inverted-bland:hover {
  color: #222 !important; /* Darker grey text for hover */
  background-color: #e6e6e6 !important; /* Slightly darker grey background */
  border-color: #bbb !important; /* Slightly darker grey border */
}

.btn-wand-inverted-bland:focus,
.btn-wand-inverted-bland:active {
  color: #111 !important; /* Dark grey text for click/focus */
  background-color: #d9d9d9 !important; /* Darker grey background */
  border-color: #aaa !important; /* Darker grey border */
  box-shadow: none !important; /* Remove Bootstrap click shadow */
}

.btn-wand-inverted-bland:disabled {
  color: #999 !important; /* Light grey text for disabled state */
  background-color: #f9f9f9 !important; /* Same background as default */
  border-color: #ddd !important; /* Light grey border for disabled state */
}

.badge-wand-inverted-2 {
  color: #fff; /* Text color */
  background-color: #ad309d; /* Background color */
  border: 1px solid #ad309d; /* Border to match text color */
  padding: 0.25em 0.4em; /* Padding for badge styling */
  border-radius: 0.25rem 0.25rem 0 0; /* Same rounded style as Bootstrap badges */
  font-size: 0.8rem; /* Small font size for badges */
  font-weight: 300; /* Light font weight */
  display: inline-block; /* Display inline-block to match badge styling */
}

.badge-wand-inverted {
  color: #5f49eb; /* Text color */
  background-color: #fff; /* Background color */
  border: 1px solid #5f49eb; /* Border to match text color */
  padding: 0.25em 0.4em; /* Padding for badge styling */
  border-radius: 0.25rem; /* Same rounded style as Bootstrap badges */
  font-size: 0.8rem; /* Small font size for badges */
  font-weight: 300; /* Light font weight */
  display: inline-block; /* Display inline-block to match badge styling */
}

.badge-wand-inverted:hover {
  color: #4a3bcf; /* Darker text color for hover */
  background-color: #f0f0f0; /* Light background for hover */
  border-color: #4a3bcf;
}

.badge-wand-inverted:focus,
.badge-wand-inverted:active {
  color: #3a2cb5; /* Even darker text color for click/focus */
  background-color: #e0e0e0; /* Slightly darker background for click/focus */
  border-color: #3a2cb5;
}

.badge-wand-inverted:disabled {
  color: #a9a9a9; /* Disabled text color */
  background-color: #fff; /* White background */
  border-color: #a9a9a9;
  opacity: 0.65; /* Reduced opacity to indicate disabled state */
}

.badge-wand {
  color: #fff; /* White text color */
  background-color: #5f49eb; /* Purple background color */
  border: 1px solid #5f49eb; /* Border to match background */
  padding: 0.25em 0.4em; /* Padding for badge styling */
  border-radius: 0.25rem; /* Same rounded style as Bootstrap badges */
  font-size: 0.8rem; /* Small font size for badges */
  font-weight: 300; /* Light font weight */
  display: inline-block; /* Display inline-block to match badge styling */
}

.badge-wand:hover {
  background-color: #4a3bcf; /* Darker purple for hover */
  border-color: #4a3bcf; /* Darker border for hover */
}

.badge-wand:focus,
.badge-wand:active {
  background-color: #3a2cb5; /* Even darker purple for click/focus */
  border-color: #3a2cb5; /* Even darker border for click/focus */
}

.badge-wand:disabled {
  background-color: #a9a9a9; /* Gray background for disabled state */
  border-color: #a9a9a9; /* Gray border for disabled state */
  color: #fff; /* White text color */
  opacity: 0.65; /* Reduced opacity to indicate disabled state */
}

/* Subtle card shadow for a professional look */
.card {
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.custom-tabs .nav-link {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
}

.custom-tabs .nav-link:hover {
  background-color: #f8f9fa;
  border-color: #ced4da;
}

.custom-tabs .nav-link.active {
  background-color: #e9ecef;
  border-color: #ced4da #ced4da #fff;
}

.custom-tabs .nav-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Modal Overlay */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000; /* Ensures it's above the sidebar */
}

/* Modal Content */
.custom-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; /* 50% of the viewport width */
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 2100; /* Above the overlay */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.construct-graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graph-card {
  width: 100%;
  max-width: 1000px; /* Adjust as necessary */
}

.graph-wrapper {
  position: relative;
  width: 100%;
  padding-top: 50%; /* 2:1 aspect ratio */
}

.construct-graph-container > .graph-card + .construct-card {
  margin-top: 20px;
  width: 100%;
  max-width: 600px; /* Adjust as necessary */
}

.construct-card {
  flex: 1;
  max-width: 600px; /* Adjust based on your preference */
}

.mailchimp-link:hover {
  text-decoration: underline;
}

.home-page-font {
  font-family: "Poppins", sans-serif;
}

.home-page {
  background-color: #f2f2f2;
}

.full-width-rating .sv-rating-item {
  width: 100%;
}

.custom-alert {
  --mdb-bg-opacity: 0.1;
  background-color: rgba(0, 123, 255, var(--mdb-bg-opacity));
}

.custom-alert-light-border {
  border: 1px solid #add8e6; /* Light gray border color */
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}

.custom-alert-light-2 {
  border: 1px solid rgba(95, 73, 235, 0.5); /* Adjusted to be a lighter, more transparent version of #5f49eb */
  /* padding: 5px; */
  padding: 1rem;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
}

.custom-light-alert {
  color: #333; /* Set your desired darker text color */
}

.custom-dark-warning-alert {
  color: #721c24; /* Set your desired darker text color for warning alerts */
}

.custom-very-dark-warning-alert {
  color: #555; /* Set your desired darker text color for warning alerts */
}

.white-text {
  color: #fff;
}

.shaped-badge {
  font-size: 1rem;
  margin: 0;
  border-radius: 0.25rem 0.25rem 0 0;
}

.shaped-alert {
  border-radius: 0 0.25rem 0.25rem 0.25rem;
}

.pythanks-border {
  border-bottom: 1px solid #ffc107;
  /* border-color: #7930E5; */
}

.pythanks2 {
  border-color: #251073;
}

.pythanks3 {
  border-color: #280d8c;
}

.pythanks4 {
  border-color: #180640;
}

.pythanks5 {
  border-color: #160626;
}

.full-width {
  width: 100%;
}

/* DashboardHolder.css */
.column-holder {
  display: flex;
}

#dashboard-holder {
  flex-grow: 1; /* Left column takes most of the space */
}

#helper-holder {
  flex-basis: 25%; /* Right column takes 25% of the space */
  margin-left: 20px; /* Add margin for spacing between columns */
}

#main-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-model-button {
  cursor: pointer;
  /* border: none; */
  /* padding: 0; */
  /* margin: 10; */
}

/* Add this CSS to your existing stylesheet or create a new one */
.date-time-picker-focused {
  border-color: #4c8bf5; /* Update with your preferred focus color */
  box-shadow: 0 0 0 0.2rem rgba(76, 139, 245, 0.25); /* Update with your preferred focus box-shadow */
}

.horizontal-line {
  border-top: 1px solid #070101;
  margin-top: 10px;
  margin-bottom: 10px;
}

.collapser {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  font-size: 1.2rem;
}

.collapser ::after {
  /* content: "  "; */
  font-size: 1.2rem;
}

.openedClassName {
  cursor: pointer;
  color: #007bff;
  /* font-weight: bold; */
  font-size: 1.2rem;
}

/* Styling for the Collapsible when it is open/expanded */
.collapser[aria-expanded="true"] {
  /* Add your styles for the open state here */
  color: #ff0000; /* Change text color to red when open */
}

.collapser[aria-expanded="true"]::before {
  content: " - "; /* Change content to "-" when open */
}

.collapsible-summary {
  cursor: pointer;
}

.header-bar {
  background-color: #292929;
}

.container--narrow {
  /* max-width: 732px; */
  max-width: 1000px;
}

.container--wide {
  max-width: 96%;
}

.header-search-icon {
  position: relative;
  top: 3px;
}

.header-chat-icon {
  cursor: pointer;
  position: relative;
  top: 3px;
}

.dark-bg-color {
  background-color: #ffffff;
  /* background-color: #f8faf5; */
}

.text-theme1 {
  color: #020659;
}

.text-theme2 {
  color: #232884;
}

.border-dark-bg-color {
  border-color: #020659;
}

.custom-outline-dark {
  border-color: #020659;
  color: #020659;
}

.chat-count-badge {
  text-align: center;
  position: absolute;
  top: 2px;
  left: 0px;
  width: 16px;
  font-size: 0.6rem;
  font-weight: bold;
}

.avatar-small {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 5px;
  position: relative;
  top: -3px;
}

.avatar-tiny {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 4px;
  position: relative;
  top: -1px;
}

.form-control-title {
  font-size: 2rem;
  font-weight: 500;
}

.form-control-textarea {
  font-size: 1rem;
  font-weight: 200;
}

.body-content {
  font-size: 1.2rem;
  line-height: 1.75;
  color: #292929;
}

.body-content p,
.body-content ul,
.body-content ol {
  margin-bottom: 1.75rem;
}

.input-dark {
  background-color: #444;
  border-color: transparent;
  color: #ffffff;
}

.input-dark:focus {
  color: #ffffff;
  background-color: #555;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-dark::-webkit-input-placeholder {
  color: #888;
}
.input-dark::-moz-placeholder {
  color: #888;
}
.input-dark:-ms-input-placeholder {
  color: #888;
}
.input-dark:-moz-placeholder {
  color: #888;
}

.container--align-right {
  margin-left: auto;
}

@media (min-width: 768px) {
  .input-dark {
    width: auto;
  }
}

.display-3 {
  font-size: 4.2rem;
}

@media (max-width: 1199px) {
  .display-3 {
    font-size: 3.4rem;
  }
}

@media (max-width: 768px) {
  .display-3 {
    font-size: 2.5rem;
  }
}

.form-group {
  position: relative;
}

.liveValidateMessage {
  top: -6px;
  position: absolute;
  z-index: 1;
  padding-top: 6px;
  padding-bottom: 16px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.liveValidateMessage--visible {
  opacity: 1;
  transform: translateY(0);
}

.liveValidateMessage-enter {
  opacity: 0;
  transform: translateY(100%);
}

.liveValidateMessage-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
}

.liveValidateMessage-exit {
  opacity: 1;
  transform: translateY(0);
}

.liveValidateMessage-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
}

.form-group input,
.form-group textarea {
  position: relative;
  z-index: 2;
}

textarea.tall-textarea {
  height: 160px;
}

@media (min-width: 768px) {
  textarea.tall-textarea {
    height: 320px;
  }
}

.delete-post-button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

/* Search Overaly */
.search-overlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(215, 215, 215, 0.911);
}

.search-overlay-enter {
  opacity: 0;
  transform: scale(1.3);
}

.search-overlay-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
}

.search-overlay-exit {
  opacity: 1;
  transform: scale(1);
}

.search-overlay-exit-active {
  opacity: 0;
  transform: scale(1.3);
  transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
}

.search-overlay-icon {
  color: #007bff;
  font-size: 1.4rem;
  margin: 0;
  margin-right: 10px;
}

.live-search-field {
  background-color: transparent;
  border: none;
  font-size: 1.1rem;
  outline: none;
  flex: 1;
  color: #007bff;
}

.live-search-results {
  opacity: 0;
  transition: all 0.3s ease-out;
  transform: scale(1.07);
}

.live-search-results--visible {
  opacity: 1;
  transform: scale(1);
}

.search-overlay-top {
  background-color: #fff;
  /* background-color: rgba(0, 0, 0, .79); */
}

.search-overlay-top .container {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.search-overlay-bottom {
  overflow: auto;
}

.close-live-search {
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.75;
  line-height: 1;
  color: #292929;
}

@media (min-width: 700px) {
  .live-search-field {
    font-size: 2.5rem;
  }

  .close-live-search {
    font-size: 3rem;
  }

  .search-overlay-icon {
    font-size: 3rem;
  }
}

.close-live-search:hover {
  opacity: 1;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.circle-loader {
  opacity: 0;
  transition: opacity 0.45s ease-out, visibility 0.45s ease-out;
  visibility: hidden;
  position: absolute;
  left: 50%;
  box-sizing: border-box;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  border: 10px solid rgba(73, 80, 87, 0.2);
  border-top-color: #495057;
  will-change: -webkit-transform, transform;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

.circle-loader--visible {
  visibility: visible;
  opacity: 1;
}

/* End Search Overlay */

/* Chat */
.chat-wrapper {
  position: fixed;
  z-index: 5;
  bottom: 0;
  right: 20px;
  width: 290px;
  height: 350px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(100%);
  transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
}

.chat-wrapper--is-visible {
  opacity: 1;
  transform: translateY(0);
}

.chat-title-bar {
  background-color: #292929;
  color: #fff;
  padding: 4px 7px;
  display: flex;
  justify-content: space-between;
}

.chat-title-bar-close {
  opacity: 0.7;
  cursor: pointer;
}

.chat-title-bar-close:hover {
  opacity: 1;
}

.chat-log {
  padding: 8px;
  flex: 1;
  overflow: auto;
}

.chat-self,
.chat-other {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.chat-self {
  padding-left: 25%;
}

.chat-self .chat-avatar {
  margin-left: 6px;
}

.chat-self .chat-message {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.chat-self .chat-message-inner {
  text-align: right;
  padding: 4px 7px;
  border-radius: 12px;
  background-color: #007bff;
  color: #fff;
}

.chat-other {
  padding-right: 25%;
}

.chat-other .chat-avatar {
  margin-right: 6px;
}

.chat-other .chat-message {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.chat-other .chat-message-inner {
  padding: 4px 7px;
  border-radius: 12px;
  background-color: #f1f0f0;
}

.chat-message a {
  color: #212529;
}

.chat-field {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 7px;
  border: none;
  outline: none;
  font-size: 0.75rem;
}

.floating-alert {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  -moz-animation: floatingAlert ease-in 5s forwards;
  -webkit-animation: floatingAlert ease-in 5s forwards;
  -o-animation: floatingAlert ease-in 5s forwards;
  animation: floatingAlert ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.floating-alerts .floating-alert:last-of-type {
  display: block;
}

@keyframes floatingAlert {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }

  9% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  91% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }
}

@-webkit-keyframes floatingAlert {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }

  9% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  91% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }
}

.small-header-avatar {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.custom-tooltip {
  padding: 8px 10px !important;
}

/* Dots Loading Animation */
.dots-loading {
  margin: 0 auto;
  text-align: center;
}

.dots-loading::before,
.dots-loading::after {
  content: " ";
}

.dots-loading div,
.dots-loading::before,
.dots-loading::after {
  margin: 35px 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #312aa8;
  opacity: 0;
}

.dots-loading::before {
  -moz-animation: opacitychange 1s ease-in-out infinite;
  -webkit-animation: opacitychange 1s ease-in-out infinite;
  -o-animation: opacitychange 1s ease-in-out infinite;
  animation: opacitychange 1s ease-in-out infinite;
}

.dots-loading div {
  -moz-animation: opacitychange 1s ease-in-out 0.33s infinite;
  -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
  -o-animation: opacitychange 1s ease-in-out 0.33s infinite;
  animation: opacitychange 1s ease-in-out 0.33s infinite;
  -webkit-animation-fill-mode: infinite;
  animation-fill-mode: infinite;
}

.dots-loading::after {
  -moz-animation: opacitychange 1s ease-in-out 0.66s infinite;
  -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
  -o-animation: opacitychange 1s ease-in-out 0.66s infinite;
  animation: opacitychange 1s ease-in-out 0.66s infinite;
  -webkit-animation-fill-mode: infinite;
  animation-fill-mode: infinite;
}

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

@-webkit-keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

/* media-manager.css (or your preferred stylesheet file) */

.thumbnail-wrapper {
  width: 100%; /* Let it shrink or grow to fill parent */
  max-width: 220px; /* Don’t exceed 220px */
  aspect-ratio: 1 / 1; /* Maintain a perfect square */
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
  margin: 0 auto; /* Optional: center horizontally */
}

.thumbnail-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

/* AuthOverlay.css */

.auth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.auth-overlay.open {
  display: flex;
}

.auth-overlay-content {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
}

.auth-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.auth-tabs .btn {
  margin: 0 5px;
}

.form-group {
  margin-bottom: 15px;
}

.liveValidateMessage-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.liveValidateMessage-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.liveValidateMessage-exit {
  opacity: 1;
  transform: translateY(0);
}
.liveValidateMessage-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

@media (max-width: 767px) {
  .auth-overlay-content {
    width: 95%;
    padding: 20px;
  }
}

/* Completely remove the bottom line below tabs */
.nav-tabs {
  border-bottom: none; /* No bottom border under the tab container */
}

/* Simplify tab links */
.nav-tabs .nav-link {
  border: none;
  background-color: transparent; /* No background color */
  color: #898884; /* Default tab text color */
  font-weight: 600;
  padding: 10px 15px;
  margin-right: 10px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent; /* No underline by default */
  box-shadow: none; /* Remove shadow from tab links */
  outline: none; /* Remove focus outline */
}

/* Underline on hover */
.nav-tabs .nav-link:hover {
  color: #5f49eb; /* Text color on hover */
  border-bottom: 2px solid #5f49eb; /* Underline on hover */
  text-decoration: none; /* Remove default text underline */
}

/* Active tab styling */
.nav-tabs .nav-link.active {
  color: #5f49eb; /* Active tab text color */
  font-weight: 600; /* Slightly bolder text for active tab */
  border-bottom: 2px solid #5f49eb; /* Underline for active tab */
  background-color: transparent; /* No background */
  border-radius: 0; /* Remove any border rounding */
  box-shadow: none; /* Remove shadow or focus outline */
  outline: none; /* Remove the thick outline on selection */
}

/* Optional: Adjust the padding for tab content */
.tab-content {
  padding: 20px 0 20px 0; /* Add spacing around tab content */
}

.header-logged-in-container {
  position: fixed;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.icon-group {
  display: flex;
  gap: 0px; /* Add spacing between icons */
  margin: 0; /* Remove unnecessary margin */
}

.tooltip {
  position: fixed !important; /* Prevent tooltip from affecting layout */
  z-index: 1050; /* Ensure tooltip stays on top */
  pointer-events: none; /* Prevent tooltip from interfering with interactions */
}

.no-hover-effect {
  /* Force no box shadow on this card */
  box-shadow: none !important;
}

.no-hover-effect:hover {
  /* Force no hover shadow */
  box-shadow: none !important;
}

/* Chat container must take the full available space */
.chat-container {
  display: flex;
  flex-direction: column;
  width: 75vw;
  max-width: 700px;
  margin: auto;
  /* background: #f9f9f9; */
  border-radius: 10px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  height: 100%; /* Ensures it takes all available space */
  min-height: 65vh; /* Prevents it from shrinking too much */
  position: relative; /* Allows absolute positioning */
}

/* Chat history should grow and push input down */
.chat-history {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Keeps messages at the bottom */
  padding: 15px;
  background: #fff;
  overflow-y: auto;
  min-height: 0; /* Important to prevent flexbox issues */
  padding-bottom: 65px;
}

/* Individual chat messages */
.chat-message {
  display: flex;
  margin-bottom: 10px;
}

.chat-message.user {
  justify-content: flex-end;
}

.chat-message.assistant {
  justify-content: flex-start;
}

.message-bubble {
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 75%;
  word-wrap: break-word;
}

.chat-message.user .message-bubble {
  background: #f4f4f4;
  font-weight: 600;
  /* color: white; */
  /* opacity: 0.8; */
}

.chat-message.assistant .message-bubble {
  background: #fdfdfd;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  color: #333;
}

/* Chat Input - Stays Fixed at Bottom */
.chat-input {
  display: flex;
  padding: 8px;
  background: white;
  /* border-top: 1px solid #ddd; */
  width: 100%;
  position: absolute; /* Ensures it's always at the bottom */
  bottom: 0;
  left: 0;
}

/* Chat input field */
.chat-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
  margin: 0;
}

/* Send button */
.chat-input button {
  margin-left: 8px;
  padding: 10px 12px;
  background: #5f49eb;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
}
